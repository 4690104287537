@import "../../../App/styles/_variables";

.is-transparent {
  background-color: transparent;
  color: $primary;
  outline: none;
  border: none;
}

.has-width-6 {
  width: 6em;
}

.is-transparent:disabled,
.is-transparent[disabled] {
  color: $gray;
}
