@import "../App/styles/_variables";

.is-client-dashboard {
  &.is-dashboard-table {
    thead {
      tr {
        th:nth-of-type(1) {
          width: 25px;
        }
      }
    }
  }
  td {
    .clickable {
      height: 3rem;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #363636;

      &.emphasis {
        color: $primary;
      }
    }
  }
  td:nth-child(1) {
    width: 10px;
  }
}
.flex {
  display: flex;
}
.is-client-container {
  display: flex;
  min-height: 90vh;
  border-bottom: 1px solid $light-gray;
  .is-client-sidebar {
    border: 1px solid $light-gray;
    width: 25%;
    min-width: 25%;
    min-height: 90vh;
    .is-client-sidebar__header {
      color: #fff;
      padding: 1rem;
      height: 90px;
      width: 100%;
      background-color: $secondary;
      .is-small-text {
        font-size: 0.8rem;
      }
      .is-big-text {
        font-size: 1.3rem;
      }
    }
    .is-client-sidebar__body {
      margin-top: 3rem;
      padding: 0px 1rem;
      height: 500px;
      .is-input-container {
        margin: 1.1rem 0 0 0;
      }
    }
  }
  .is-client-body {
    width: 75%;
    table-layout: fixed;
    border-collapse: collapse;
    .is-client-site-table {
      thead {
        border-bottom: 3px double $light-gray;
        width: 100%;
        tr {
          position: relative;
          width: 100%;
        }
      }
      tbody {
        overflow-y: auto;
        width: 100%;
      }
    }
    th {
      text-align: center;
    }
    td {
      .clickable {
        height: 3rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #363636;

        &.emphasis {
          color: $primary;
        }
      }
    }
  }
}

// New
#form-client-info {
  div {
    justify-content: center;
  }
  input,
  select {
    font-size: 0.9rem;
  }
}
