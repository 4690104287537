@import "../../../App/styles/_variables";

.level {
  display: flex;
  justify-content: start;
  cursor: pointer;
}

.level-item {
  border-bottom: 3px solid $very-light-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  padding-bottom: 0.5em;
  width: 9.5rem;

  &__title {
    font-weight: bold;
    color: $gray;
  }

  &__badge {
    min-width: 3rem;
    min-height: 1rem;
    // padding: 0.1rem 0.4rem;
    color: $gray;
  }

  &--is-active {
    border-bottom: 6px solid $primary;
  }
}
