@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

// Initial font variable
$family-sans-serif: "Lato", sans-serif;

// Initial color variables
$teal: #0d6d6e;
$light-teal: #008080;
$very-light-teal: #d7ecee;
$red: #fe385f;
$orange: #f57f20;
$yellow: #ffe270;
$yellow-orange: #ffb81f;
$green: #16a57d;
$purple: #4c0b87;
$white: #ffffff;
$gray: #777;
$light-gray: #9e9e9e;
$very-light-gray: #eeeeee;
$black: #000000;
$light-gray-inverse: #e9e9e9;
$super-light-gray: #f6f6f6;
$dark-gray: #333;
$gray: #666666;
// Derived variables
$primary: $teal;
$secondary: $orange;
$light: $very-light-teal;
$link-hover: $light-teal;
$success: $green;
$hr-background-color: $light-teal;
$hr-height: 1px;
$border: $very-light-teal;
$border-hover: $teal;
$navbar-height: 3.25rem;
$spacer: 0.5rem;
$radius: 0;
