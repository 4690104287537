@import "../App/styles/__variables.scss";

// Print settings
@media print {
  .navbar,
  .notification {
    display: none;
  }

  .container:last-of-type {
    padding-bottom: 0;
  }

  .sales-invoice {
    border: none;
  }

  .drugcard-footer,
  .dr-footer,
  .sales-invoice {
    page-break-after: always;
  }

  html.has-navbar-fixed-top,
  body.has-navbar-fixed-top {
    padding-top: 0;
  }

  .container {
    margin-top: 0;
  }

  article.drugcard-info,
  .drugcard-header,
  .drugcard,
  .drugcard-footer,
  .dr-header,
  .dr-terms,
  .dr-footer {
    page-break-inside: avoid;
  }

  article:nth-of-type(7),
  article:nth-of-type(8n + 7) {
    page-break-after: always;
  }
}

// Print message on top

.print-message {
  padding: 0.4rem 0;
}

.is-width-20 {
  width: 30% !important;
}
// For all drug card and DR pages
.drugcard-header,
.drugcard,
.drugcard-footer,
.dr-body,
.dr-terms,
.dr-footer {
  p,
  div,
  th,
  td,
  li {
    font-size: 0.6rem;
  }

  table {
    page-break-after: auto !important;

    tbody,
    tfoot,
    thead {
      display: table-header-group !important;
      tr {
        page-break-inside: avoid !important;
        page-break-after: auto !important;

        td {
          padding: 0 0.3rem;
          margin: 0;
          border: 0;
          .text-right {
            text-align: right !important;
          }

          page-break-inside: avoid !important;
          page-break-after: auto !important;
        }
      }
    }
  }

  hr.divider {
    margin: 0.25rem 0;
  }
}

// Drug Card

.drugcard-header {
  margin: 0.75rem 0;

  .columns {
    margin-top: 0.75rem;
  }

  img.logo {
    max-width: 90%;
    float: right;
  }

  td.label {
    color: $light-gray;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    width: 10%;
  }
}

.drugcard {
  article.drugcard-info {
    padding: 0.5rem 0;
    margin: 0;
    border: 0;

    .molecule-avatar {
      width: 100%;
      height: 100%;
      border: 1px solid $primary;
      padding-top: 0.3rem;
      border-radius: 5%;
      line-height: 1.5;
      color: $primary;
      text-align: center;
      text-transform: capitalize;
      font-weight: bold;
    }

    p.molecule-title {
      font-size: 0.9rem;
      font-weight: bold;
      color: $primary;
    }

    p.indication {
      font-size: 0.75rem;
      color: $light-gray;
    }

    ul {
      margin-top: 0.25rem;
    }

    p.expiry {
      color: $gray;
      font-style: italic;
    }
  }
}

.drugcard-footer {
  .h2 {
    font-weight: bold;
    font-size: 1rem;
    color: $teal;
  }
}

// DR

// DR header section >>>>>
.dr-header {
  .dr-header-contents {
    justify-content: space-between;

    p {
      color: $gray;
    }
  }

  hr.divider {
    margin: 0.25rem 0;
  }

  p.customer {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    color: $primary;
  }

  .column {
    border: 0;
    margin-top: 1.5rem;
    padding: 0.25rem 1rem;
  }

  &-row {
    page-break-after: auto;
    page-break-inside: avoid;
  }
}

// DR top section >>>>>
.dr-top-section {
  h1 {
    color: $teal;
  }

  // personal & deliver section
  .personal-delivery-section {
    justify-content: space-between;

    .personal-information p {
      width: 25rem;
    }

    .delivery-information p {
      color: $gray;
    }

    .delivery-information {
      .delivery-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .delivery-info {
        padding: 0.25rem 0.5rem !important;
        border: 0.0625rem solid $very-light-teal;
        width: 100%;
      }
    }
  }

  // billing information section
  .billing-information-section {
    justify-content: space-between;

    .information-section {
      width: 100%;
    }

    .info-container {
      width: 28.125rem;

      .billing-info-label {
        width: 15rem;
      }

      .total-amount-container {
        border: 0.0625rem solid $light-teal;
      }
    }

    .qr-code-container {
      border: 0.0625rem solid $very-light-teal;
      width: 10.625rem;
    }

    .nps-section {
      display: flex;
      justify-content: center;
    }
  }
}

// DR terms section >>>>>
.dr-term-section {
  page-break-inside: avoid;
  page-break-after: auto;

  .terms-header {
    color: $teal;
  }
}

// DR body section >>>>>
.dr-body-section {
  min-height: 21.875rem;
  .order-header {
    color: $teal;
  }
}

// DR computation section >>>>>
.computation-section {
  flex-direction: row-reverse;
  page-break-inside: avoid;
  page-break-after: auto;

  .computation {
    width: 15rem;
    justify-content: space-between;
  }

  .total-amount {
    border-top: 0.0625rem solid $teal;
  }

  &--page-break {
    page-break-before: always;
  }
}

.customer-signature {
  page-break-inside: avoid;
  page-break-after: always;
}

table {
  &.dr-body {
    page-break-after: auto !important;

    thead,
    tbody {
      tr {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        border: 0.0625rem solid $very-light-teal;
        th,
        td {
          border: 0.0625rem solid $very-light-teal;
          padding: 0.25rem;
        }

        th {
          text-align: center;
          color: $gray;
        }
      }
    }

    thead {
      display: table-header-group !important;

      tr {
        th {
          page-break-inside: avoid !important;
          page-break-after: auto !important;
          border-bottom: 0.1875rem solid $teal;
        }
        page-break-inside: avoid !important;
        page-break-after: auto !important;
      }
    }
  }

  &.dr-terms {
    background: transparent;

    thead,
    tbody {
      tr {
        th,
        td {
          padding: 0 0.25rem;
          font-size: 0.5rem;
        }
      }
    }
  }

  &.dr-feedback {
    img {
      width: 80%;
    }
  }
}

table.table:not(:last-child) {
  margin-bottom: 0;
}

.drugcard-footer {
  padding-bottom: 1rem;
  margin-top: 0.5rem;
}

.dr-order-details,
.dr-total {
  tr.has-border-bottom,
  td.has-border-bottom {
    border-bottom: 1px solid $very-light-teal;
  }

  tr.has-border-top,
  td.has-border-top {
    border-top: 1px solid $very-light-teal;
  }
}

// Sales invoice
.sales-invoice {
  position: relative;
  font-size: 11px;
  color: $black;
  padding: 0px 45px 0 55px;
  width: 100%;

  strong {
    color: $black;
  }

  .sales-invoice__header {
    .date {
      margin-top: 43.5mm;
    }

    .comp-ref {
      margin-top: 4mm;
    }

    .charged-to {
      margin-top: 6.5mm;
      margin-left: 30mm;
    }

    .tin {
      margin-top: 2.5mm;
      margin-left: 15mm;
    }

    .address {
      margin-top: 2.5mm;
      margin-left: 25mm;
    }
  }

  table {
    margin-top: 10.5mm;
    margin-left: 7mm;
    width: 98%;

    &.sales-invoice__order-details tbody td,
    &.sales-invoice__order-details thead th {
      padding: 0.25mm;

      &.code {
        width: 23mm;
      }

      &.description {
        width: 60mm;
      }

      &.uom,
      &.qty,
      &.unit-price,
      &.amount {
        width: 8mm;
      }
    }

    &.sales-invoice__order-details thead {
      tr:first-of-type {
        border-bottom: 1px solid black;
        font-size: 9px;
      }
    }

    &.sales-invoice__order-details tbody td {
      padding-left: 4mm;
      text-indent: -4mm;
    }
  }

  .sales-invoice__totals {
    position: absolute;
    top: 142mm;
    right: 14mm;
    width: 22%;

    p {
      margin-bottom: 0.4mm;
    }
  }
}

//Feedback

ul#feedback {
  .pagination-link {
    border: none;
    font-size: 0.6rem;
    margin: 0;
  }
}

@page {
  size: A4;
  margin: 2rem;
}

.is-proper {
  text-transform: capitalize;
}
.number-column {
  width: 1em;
}
.qty {
  width: 8mm;
}

.float-right {
  text-align: right;
}
