table.is-purchase-order-dashboard {
  thead {
    tr {
      th:nth-of-type(1) {
        width: 0;
      }
    }
  }

  tbody {
    tr {
      td:nth-of-type(1) {
        width: 0;
      }
    }
  }
}

.is-size-8 {
  margin-top: 0.5rem;
  font-size: 0.65rem;
}

.disabled-link {
  pointer-events: none;
}

table.transaction-details tfoot.transaction-total tr td .select {
  padding-left: 0rem;
}
