@import "../App/styles/_variables";

.login {
  height: 100%;
  &__top-background {
    background-color: $very-light-teal;
    display: block;
    width: 100%;
    height: 30%;
    position: absolute;
    top: 0;
  }

  &__card {
    &-is-centered {
      display: flex;
      justify-content: center;
    }
  }
}
