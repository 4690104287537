@import "../../App/styles/_variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.body {
  width: 100%;
  border-spacing: 1em;

  td {
    padding: 1em 0px;
  }

  td:nth-child(1) {
    width: 30%;
  }
}

.medicine {
  border-bottom: 0.15em solid $super-light-gray;

  &__header {
    border-bottom: 0.15em solid $super-light-gray;
  }

  &__sub-details {
    display: flex;
    justify-content: space-between;
  }

  &__footer {
    display: block;
    text-align: right;
  }
}

.target-delivery-date {
  border-collapse: collapse;
  border: none;

  tr:nth-child(1) td {
    border: none;
    text-align: left;
  }

  td {
    padding: 0px;
    width: 1.5em !important;
  }

  tr:nth-child(2) td {
    border: 1px solid $primary;
  }

  tr:nth-child(2) td:nth-child(3),
  tr:nth-child(2) td:nth-child(7),
  tr:nth-child(2) td:nth-child(3):before,
  tr:nth-child(2) td:nth-child(7):before {
    content: "-";
    text-align: center;
    border: none;
  }
}

.signature {
  text-align: center;
}
