@import "../../../App//styles/_variables";

.circle-notif {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $primary;
}
.notif-container {
  position: relative;
}

.circle-notif.is-error {
  background-color: red;
}

.circle-notif.is-warning {
  background-color: $secondary;
}
.circle-notif.is-accepted {
  background-color: $primary;
}

.circle-notif.is-small {
  width: 10px;
  height: 10px;
}
.circle-notif.is-medium {
  width: 15px;
  height: 15px;
}
.circle-notif.is-big {
  width: 20px;
  height: 20px;
}
