@import "../../App/styles/__variables.scss";

table {
  &.is-dashboard-table {
    th>label.checkbox-field {
      margin-top: -.75rem;
    }
  }

  // GENERAL FORMATTING FOR TABLES
  &.table {

    tfoot,
    thead,
    tbody {
      tr {
        th {
          border: 0.25rem solid $white;
        }

        td {
          border-width: 0;
        }
      }

      &.dashboard {
        max-height: calc(100vh - 29rem);
        overflow-y: auto;
        display: block;
      }
    }
  }

  // TransactionDetails
  &.transaction-details {
    margin: 2rem 0;

    th {
      text-align: center;

      &.header-meds {
        text-align: left;
        width: 50%;
      }
    }

    tr {
      &.list-meds {
        padding: 0.5rem;

        td {
          padding: 0.25rem 0.75rem;

          // Delete button hidden; visible only on tr:hover
          &.is-narrow {
            padding: 0.6rem 0;
            visibility: hidden;
          }

          .is-static,
          input[name="quantity"] {
            text-align: right;
          }
        }
      }

      &:hover {
        td.is-narrow {
          visibility: visible;
        }
      }

      &.add-meds {
        border-bottom: 1px solid $light-teal;

        .add-row {
          cursor: pointer;
          color: $primary;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .is-search-results {
        position: absolute;
        z-index: 4;
        padding: 0.25rem;
        margin-bottom: 1rem;
        width: 48%;
        height: 10rem;
        max-height: 10rem;
        overflow-y: auto;
        border: 1px solid $very-light-gray;
        background: $white;

        table {
          &.is-hoverable {

            tr:hover,
            .is-selected {
              background: $very-light-teal;
              color: $black;
            }
          }
        }
      }
    }

    tfoot {
      &.transaction-total {
        tr {
          td {
            &.transaction-total__right {
              text-align: right;
              padding: 0.5rem;

              &.top {
                border-top: 1px solid $light-teal;
                padding-top: 1rem;
              }
            }

            hr {
              margin: 0.25rem 0;
            }

            .select {
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
}