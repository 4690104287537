@import "../App/styles/__variables.scss";

.dashboard-level {
  margin-bottom: 5rem;

  .level-item {
    margin: 1rem 0.5rem;
    padding: 0.75rem 0.5rem;
    box-shadow: none;
    border: none;

    .count {
      margin-bottom: 0.25rem;
    }

    .tooltip:hover::before {
      font-size: 1.5rem;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
}

table {
  // Dashboard headers
  &.is-dashboard-table {
    thead {
      tr {
        border-bottom: 0.35rem solid $very-light-gray;

        th {
          padding: 0.75rem 0;
          cursor: pointer;
        }
      }
    }

    thead tr,
    tbody tr {
      display: table;
      table-layout: fixed;
      width: 100%;
      word-wrap: break-word;

      th:nth-of-type(1) {
        width: 6%;
      }

      td:nth-of-type(1) {
        width: 4%;
        padding: 1.5rem 0.5rem;
        .checkbox-field {
          margin-left: 0.5rem;
        }
      }

      &.dashboard-row {
        td {
          vertical-align: middle;

          span.icon {
            margin-right: 0.3rem;
          }

          &.status {
            width: 14rem;

            a.transaction-status {
              display: block;
              width: 100%;
              color: $white;
              padding: 0.5rem 0;
              background-color: $light-teal;
            }
          }
        }
      }
    }
  }
}
