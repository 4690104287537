@import "../../../App/styles/_variables";

$very-light-shadow: #f2f2f2;

.div-table {
  position: relative;
  min-width: 768px;
  .is-striped:nth-child(even) {
    background: $very-light-shadow;
  }
}

.div-table-row {
  display: flex;
  min-height: 3.5rem;
  border-bottom: 1px solid $very-light-shadow;

  .hover-delete {
    display: none;
  }

  &:hover {
    background: $very-light-shadow;
    button {
      display: block;
      outline: none;
      font-weight: bold;
    }

    .hover-delete {
      display: inline;
    }
  }
  &.row-table-header {
    font-weight: bold;
    top: 0;
    color: $black;
    z-index: 10;
  }
  .div-table-cell {
    margin: 0 0.5rem;
    padding: 0.5rem 0;
    flex: 1 1 0;
    font-size: 0.8em;

    input,
    textarea {
      font-size: 0.9rem;
      margin: -5px;
    }
    // &.cell-priority {
    // flex-grow: 5;
    // }
    &.cell-normal {
      flex-shrink: 1;
    }
  }
}
