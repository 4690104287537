@import "../../App/styles/_variables";

.width-50 {
  width: 50px;
}

.none {
  display: none;
}

.margin-auto {
  margin: auto;
}

.loading-circle {
  $size: 1em;
  $speed: 0.7s;
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  animation: circ-anim $speed linear infinite;
}
.loading-circle-primary {
  border: 3px solid $primary;
  border-bottom-color: $light;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.level-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  font-size: 0.9em;
  .loading-circle-primary {
    width: 4em;
    height: 4em;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dpo.tooltip:hover::before {
  white-space: pre-wrap;
}

.modal-card-head {
  border-radius: 0px;
}

.modal-card-foot {
  justify-content: center;
  border: none !important;
  border-radius: 0;
}

.fullwidth {
  width: 100%;
}

.loading-dots {
  color: $primary;
  font: 500 1.3em Impact;
  text-align: center;
}

/* loading dots */
.loading-dots:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: $primary;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 $primary, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 $primary, 0.5em 0 0 $primary;
  }
}

.is-active-row {
  border-bottom: 1px solid $primary;
}
