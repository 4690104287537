@import "../../../App/styles/_variables";

.is-pick-list-dashboard {
  & table {
    & tbody,
    thead {
      & tr {
        & td,
        th {
          width: 5%;

          &:nth-of-type(1) {
            width: 2px;
          }
          &:nth-of-type(2) {
            width: 20%;
          }

          &:nth-of-type(6),
          &:nth-of-type(5) {
            width: 8%;
          }
          &:last-of-type {
            width: 2px;
          }
        }
      }
    }
  }
}

.is-saving {
  color: $light-gray;
  width: 9.5em;
  margin-bottom: 1em;
}
