.is-sourcingsheet-dashboard {
  & table {

    & thead,
    & tbody {
      & tr {
        padding-top: 1em;
        font-size: 0.87em;
        margin: 0 !important;

        & th,
        & td {
          &:nth-of-type(1) {
            width: 1%;
          }

          &:nth-of-type(2) {
            width: 7%;
          }

          &:nth-of-type(3) {
            width: 15%;
          }

          &:nth-of-type(6) {
            width: 7%;
          }

          &:nth-of-type(5),
          &:nth-of-type(4),
          &:nth-of-type(8),
          &:nth-of-type(7) {
            width: 4%;
          }

          &:nth-of-type(9) {
            width: 1%;
          }

          .td-status {
            padding: 0.4em;
            color: white;
            text-align: center;
            font-weight: bold;
          }
        }

        & td {
          &:nth-of-type(9) {
            padding: 0 40px;
          }
        }
      }
    }
  }
}