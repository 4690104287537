@import "../../../App/styles/_variables";

$sidebar-width: 18rem;

.content-container {
  position: relative;
  display: flex;
  height: calc(100vh - #{$navbar-height});
  width: 100%;
  label {
    font-size: 0.85rem;
  }
}

.panel.panel-content {
  margin: 1rem;
  .panel-heading.panel-header {
    background: white;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: $hr-height solid $light-gray;
    border-radius: 0;
    background: transparent;
    padding: 0.5rem 0;
  }

  .panel-body {
    margin: 1rem 0;
  }
}

.sidebar-container {
  background-color: rgba(188, 215, 215, 0.3);
  height: 100%;
  width: $sidebar-width;
  border-right: 1px solid lightblue;
  overflow-y: auto;
}

.main-content.container {
  margin: 0px auto;
  overflow-y: auto;
}

.textarea-custom {
  border-radius: 0;
  border-color: $very-light-teal;
}
