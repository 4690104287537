@charset "utf-8";

@import "./_variables.scss";
@import "./_mixins.scss";

@import "../../../node_modules/bulma/bulma.sass";
@import "../../../node_modules/bulma-tooltip/dist/css/bulma-tooltip.sass";
@import "~bulma-checkradio";

body {
  cursor: default;
}

a,
a:link,
a:visited {
  color: $primary;
}

strong,
b {
  color: $primary;
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
}

.container {
  margin-top: 50px;

  img.image {
    margin: 0 auto;
  }

  &:last-of-type {
    padding-bottom: 6rem;
  }
}

button,
a.button {
  border-radius: 0px !important;

  &.button {
    border-radius: 0px !important;
  }
}

// Scrollbar
::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background-color: $teal;

  &:hover {
    background-color: $light-teal;
  }
}

.has-text-purple {
  color: $purple;
}

.has-background-light {
  background-color: $light;
}
.has-text-yellow-orange {
  color: $yellow-orange;
}
.has-text-orange {
  color: $orange;
}

@for $i from 0 through 5 {
  .m-#{$i},
  .mx-#{$i},
  .mr-#{$i} {
    margin-right: $spacer * $i !important;
  }

  .m-#{$i},
  .mx-#{$i},
  .ml-#{$i} {
    margin-left: $spacer * $i !important;
  }

  .m-#{$i},
  .my-#{$i},
  .mt-#{$i} {
    margin-top: $spacer * $i !important;
  }

  .m-#{$i},
  .my-#{$i},
  .mb-#{$i} {
    margin-bottom: $spacer * $i !important;
  }

  .p-#{$i},
  .px-#{$i},
  .pr-#{$i} {
    padding-right: $spacer * $i !important;
  }

  .p-#{$i},
  .px-#{$i},
  .pl-#{$i} {
    padding-left: $spacer * $i !important;
  }

  .p-#{$i},
  .py-#{$i},
  .pt-#{$i} {
    padding-top: $spacer * $i !important;
  }

  .p-#{$i},
  .py-#{$i},
  .pb-#{$i} {
    padding-bottom: $spacer * $i !important;
  }
}

.tabs {
  ul {
    border-color: $very-light-gray;

    li {
      a {
        background-color: $light;

        .count {
          border-radius: 50%;
          font-weight: bold;
          color: $black;
          background: $light-gray;
          margin-left: 1rem;
          padding: 0.25rem 0.75rem;
        }
      }

      &.is-active {
        a {
          background-color: $primary !important;
          color: $white;

          .count {
            background: $very-light-gray;
          }
        }
      }
    }
  }
}

@media print {
  body {
    padding: 0rem !important;
    margin: 0rem !important;
  }
}

.has-text-secondary {
  color: #ee7423;
}
