@import "../../../App/styles/_variables";

// Print settings

@media print {
  .po {
    // Ensures that both footer and subtotal are one block (does not break inside on page break)
    &__footer, &__subtotal {
      page-break-inside: avoid;
    }
  }
}

.po {
  & p,
  div,
  th,
  td,
  li {
    font-size: 0.6rem;
  }

  &-divider {
    margin: 0.25rem 0;
    border: .125rem;
  }

  &__header {
    &-column {
      border: 0;
      margin-top: 1.5rem;
      padding: 0.25rem 1rem;
    }

    &-customer {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bold;
      color: $primary;
    }
  }

  &__body {
    & table.table {
      & thead, tbody {
        & th {
          text-align: center;
        }
      }
    }
  }

  &__subtotal {
    & tbody > tr > td {
      font-size: 0.8rem;
      text-align: right;
    }
  }

  &__footer {
    & h3 {
      margin: .5rem;
    }
  }

  &__terms {
    th, td {
      padding: .125rem 0.5rem;
      font-size: 0.5rem;
    }
  }
}