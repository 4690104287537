@import "../../App/styles/__variables.scss";

.footer {
  display: block;
  background-color: $light;
  position: fixed;
  bottom: 0;
  padding: 1.5rem;
  width: 100%;
  left: 0;
  z-index: 10;
}
