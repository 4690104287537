@import "../../App/styles/__variables.scss";

.navbar-item,
.navbar-dropdown,
.is-hoverable,
.has-dropdown,
.navbar-link {
  &:hover,
  &:focus,
  &:focus-within,
  &__is-active {
    background-color: $light-teal !important;
    color: $white;
  }
}
