@import "../App/styles/_variables";

.dashboard {
  & thead {
    border-bottom: 0.35rem solid $very-light-gray;

    & tr {
      display: table;
      table-layout: fixed;
      width: 100%;
      word-wrap: break-word;
    }

    & th {
      padding: 0.75rem 0;
      // cursor: pointer;

      &.checkbox {
        padding: 1.25rem 0.5rem;
      }
    }
  }

  & tbody {
    & tr {
      display: table;
      table-layout: fixed;
      width: 100%;
      word-wrap: break-word;

      & td {
        vertical-align: middle;
      }
    }
  }
}
