@import "../../../App/styles/_variables";
.search-bar {
  /* display: inline-block; */
  width: 100%;
}

.option.no-value-found {
  color: #a6a6a6;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option.option-el {
  border-bottom: 1px solid #e6e6e6;
  padding: 0.3rem;
  color: #444;
  cursor: pointer;
}

#options {
  z-index: 999;
  text-align: left;
  position: absolute;
  padding: 0.5rem;
  min-height: 1rem;
  max-height: 20rem;
  max-width: 30rem;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  min-width: 10.5rem;
  color: #a6a6a6;
  overflow-y: scroll;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.option-el:hover {
  background-color: $super-light-gray;
}

.selected-option {
  background-color: $primary !important;
  color: #fff !important;
}
