@import "../../App/styles/_variables";

.section {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $primary;
}

@media print {
  .no-print {
    display: none !important;
  }
}
