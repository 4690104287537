@import "../../../App/styles/_variables";

.loader {
  margin: 0 auto;
  text-align: center;

  div {
    margin: 0 0.1rem;
    background-color: $primary;

    border-radius: 100%;
    display: inline-block;
    animation: dot-bouncedelay 1.4s infinite ease-in-out both;
  }

  &--is-small div {
    width: 0.25rem;
    height: 0.25rem;
  }

  &--is-normal div {
    width: 0.75rem;
    height: 0.75rem;
  }

  .dot-1 {
    animation-delay: -0.32s;
  }

  .dot-2 {
    animation-delay: -0.16s;
  }
}

@keyframes dot-bouncedelay {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0.2);
  }

  40% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
