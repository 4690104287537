@import "../../App/styles/_variables.scss";
@import "../../App/styles/_mixins.scss";

.notification {
  display: flex;
  padding: 1.5rem;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 5rem;
  z-index: 11;

  @keyframes toast {
    0% {
      margin-bottom: -18rem;
    }
    10% {
      margin-bottom: 0;
    }
    50% {
      margin-bottom: 0;
    }
    90% {
      margin-bottom: 0;
    }
  }

  &__toast {
    align-self: center;
    color: white;
    opacity: 0.8;
    filter: alpha(opacity=80);
    animation-name: toast;
    animation-timing-function: ease-in;
    animation-direction: alternate-reverse;
    animation-duration: 3.5s;
    margin-bottom: -18rem;
  }
}