@import "../../App/styles/_variables";

.input.has-cursor-disabled {
  cursor: not-allowed;
  color: black;
}

.is-product-request-dashboard {
  thead {
    tr {
      th:nth-child(1) {
        width: 4rem !important;
      }
    }
  }
  tbody {
    tr {
      td:nth-child(1) {
        width: 4rem;
      }
    }
  }
}

.search-field {
  // width: 20rem;
}
