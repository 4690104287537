@import "../../App/styles/__variables.scss";

// SELECT

.select {
  select {
    width: 100%;
    background-color: $white;
    color: $black;
    border-radius: 0;
    border: 1px solid $light;
  }
}

.select:not(.is-multiple) {
  width: 100%;
}

// INPUT

input.input {
  background-color: $white;
  color: $black;
  border-radius: 0;
  border: 1px solid $light;
}

.is-danger {
  color: $red;
}

// CHECKBOX
/* customize the checkbox (the container) */
.checkbox-field {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $white;
    border: 1px solid $primary;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.checkmark {
    background-color: $light-teal;
  }

  input:checked~.checkmark:after {
    display: block;
  }
}

.checkbox-field:hover input~.checkmark {
  background-color: $light;
}

// TEXTAREA (SMS)
.box {
  border-radius: 0;
  text-align: left;

  &.text-field {
    background-color: $very-light-gray;
    margin: .5rem 0;
  }
}

// COUPON FORMS & STEPS
span.steps-marker {
  cursor: pointer;
}

.coupon-form {
  padding: 0 15%;
  margin-bottom: 10%;
}

.centered {
  margin-bottom: 1%;
}